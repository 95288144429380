<template>
    <div class="onebyone-container">
        <div class="cards">
            <div
                :class="['card', { active: isSelected(item) }, { disabled: isDisabled(item) }]"
                v-for="(item, index) in cards"
                :key="'item' + index"
                @click="handleRemove(item)"
                :style="{
                    width: getCardSize.width + 'px',
                    height: getCardSize.height + 'px'
                }"
            >
                <div v-if="isSelected(item)" class="checked"></div>
                <img v-if="item.id <= 54" :src="require(`@/assets/img/cards/${item.id}-${language}.png`)" />
            </div>
        </div>

        <div class="size-adjustment">
            <a class="btn" @click="increaseSize()">+</a>
            <span class="percent">{{ size }}%</span>
            <a class="btn" @click="decreaseSize()">-</a>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    props: {
        cards: {
            type: Array,
            default () {
                return []
            },
        },
        selected: {
            type: Array,
            default () {
                return []
            },
        },
    },

    data () {
        return {
            size: 100,
            sizeArray: {
                "100": {
                    width: 100,
                    height: 130
                },
                "80": {
                    width: 90,
                    height: 120
                },
                "60": {
                    width: 80,
                    height: 110
                },
                "40": {
                    width: 70,
                    height: 100
                }
            },
        }
    },

    computed: {
        ...mapFields(['completed', 'language']),

        getCardSize() {
            return this.sizeArray[this.size]
        }
    },

    methods: {
        handleRemove (item){
            this.$emit('removeItem', item)
        },

        isSelected (val) {
            return this.selected.find(item => item.id === val.id)
        },

        isDisabled (val) {
            return this.completed.find(item => item.id === val.id)
        },

        decreaseSize() {
            if (this.size <= 40) return false
            this.size = this.size - 20;
        },

        increaseSize() {
            if (this.size >= 100) return false
            this.size = this.size + 20;
        }
    }
}
</script>

<style lang="scss" scoped>
.onebyone-container {
    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        max-width: 1400px;
        margin: auto;
        padding-bottom: 170px;

        .card {
            position: relative;
            cursor: pointer;
            width: 100px;
            height: 130px;
            margin: 10px;
            box-shadow: 0 3px 6px #00000047;
            border-radius: 4px;
            transition: width 1s,height 1s;
            z-index: 1;

            img {
                border-radius: 4px;
                width: 100%;
                height: 100%;
                filter: grayscale(100%);

                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
                transform-origin:  top left;
            }

            &:hover {
                z-index: 10;
                
                .checked {
                    transform: scale(2.1);
                    z-index: 10;
                    filter: grayscale(0%);
                    position: absolute;
                    top: 0;
                    right: 0px;
                }

                img {
                    transform: scale(2.1);
                    z-index: 9;
                    position: relative;
                    filter: grayscale(0%);
                }
            }

            &.active {
                img {
                    outline: 3px solid #00AEEF;
                }
            }

            &.active {
                opacity: 1;
                outline: 3px solid #00AEEF;

                img {
                    filter: grayscale(0%);
                }

                .checked {
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    background-color: #00AEEF;

                    -webkit-transition: all .2s ease-in-out;
                    -moz-transition: all .2s ease-in-out;
                    -o-transition: all .2s ease-in-out;
                    transition: all .2s ease-in-out;
                    transform-origin:  top left;

                    &:after {
                        content: "";
                        width: 13px;
                        height: 8px;
                        border: 2px solid #fff;
                        border-top: none;
                        border-right: none;
                        position: absolute;
                        left: 5px;
                        top: 2px;
                        transform: rotate(-46deg);
                    }
                }

                &:hover {
                    .checked {
                        transform: scale(2.1);
                        z-index: 10;
                        filter: grayscale(0%);
                        position: absolute;
                        top: 0px;
                        right: -88px;
                        width: 20px;
                        height: 20px;
                        border-bottom-left-radius: 4px;
                    }
                }
            }

            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }
        }
    }

    .size-adjustment {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 100px;
        left: 10px;
        // right: 0;
        margin: auto;
        width: 132px;
        height: 40px;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 3px;
        padding: 5px 10px;

        @include desktop {
            right: 0;
            left: 0;
        }
        // z-index: 999;

        // @include media-breakpoint-down(lg) {
        //     left: unset;
        //     right: 20px;
        // }

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            font-size: 24px;
            text-align: center;
            color: #161616;
            font-weight: 600;
            cursor: pointer;
        }
        .percent {
            display: block;
            width: 100%;
            font-size: 18px;
            text-align: center;
            color: #161616;
            font-weight: bold;
        }
    }
}
</style>